import {Component} from '@angular/core';
import {Punch, SendMethod, SendToOtherService} from "../../domain/models";
import {ActivatedRoute, Router} from "@angular/router";
import {OpenbandService} from "../../services/openband/openband.service";
import {NotificationService} from "../../services/notifications/notification.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {interval} from "rxjs";
import {FilterListByBibPipe} from "../../helpers/pipes/filter-list-by-bib.pipe";
import {FilterListByPointPipe} from "../../helpers/pipes/filter-by-point/filter-list-by-point.pipe";
import {Title} from "@angular/platform-browser";
import {PreferencesService} from "../../services/preferences/preferences.service";
import {FilterListByPriorityPipe} from "../../helpers/pipes/filter-list-by-priority.pipe";

@Component({
  selector: 'app-device-punches',
  templateUrl: './device-punches.component.html',
  styleUrls: ['./device-punches.component.scss']
})
export class DevicePunchesComponent {
  data: Punch[];
  deviceId: string;
  searchText = '';
  searchPriority = '';

  private subscription

  selectedPoint = ""
  timingPoints: string[] = ["-"]
  count: number = 0
  punchToEdit: Punch;
  punchToCreate: Punch = new Punch();

  parseRouteParams(params: string) {
    var splitted = params.split(";")
    this.deviceId = splitted[0];
    this.initializePunchForCreate()
    this.loadPunches()
    this.subscription = interval(5000).subscribe((val) => {
      this.loadPunches()
    })
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: OpenbandService,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    private titleService: Title,
    private prefs: PreferencesService,
  ) {
    route.params.subscribe(params => {
        this.parseRouteParams(params.id.toString())
        this.api.getDevice(this.deviceId).subscribe(
          res => {
            titleService.setTitle(`Device ${this.deviceId} ${res.name}`)
          },
          err => {

          }
        )
        titleService.setTitle(`Device ${this.deviceId}`)
        this.searchText = params['search'];
        this.selectedPoint = params['timingPoint'];
        this.searchPriority = params['priority']
      }
    )
  }

  loadPunches() {
    this.api.getPunchesByDeviceIdFrom(this.deviceId, this.prefs.appPreferences.fromTimestamp).subscribe((res) => {
      this.sortPunches(res)
    })
  }

  sortPunches(res: Punch[]) {
    this.data = res.sort((a, b) => {
      if (a.timestamp == "" || a.timestamp == null || a.timestamp == null) return 11;
      if (a.timestamp < b.timestamp) return 1;
      if (a.timestamp > b.timestamp) return -1;
      return -1;
    }).reverse()

    let bibs = {}
    this.data.forEach(punch => {
      if (punch.bib.length > 0 && punch.isValid) {
        if (bibs[punch.bib]) {
          bibs[punch.bib] += 1;
        } else {
          bibs[punch.bib] = 1;
        }
        if (bibs[punch.bib] > 1) punch.isDuplicate = true; else punch.isDuplicate = false
      }
    });
    this.data = this.data.reverse()
    this.recalculateCount()
    this.loadTimingPoints()
  }

  loadTimingPoints() {
    this.data.forEach(x => {
      if (this.containsObject(x.timingPoint, this.timingPoints) == false) {
        this.timingPoints.push(x.timingPoint)
      }
    })
  }

  ngOnInit(): void {

  }

  containsObject(obj: string, list: string[]) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }

    return false;
  }

  filterByBibPipe = new FilterListByBibPipe();
  filterByPointPipe = new FilterListByPointPipe();
  filterByPriorityPipe = new FilterListByPriorityPipe();
  punchCreateString: string = "";

  sendPunchToResults(punch: Punch) {
    this.sendToResults([punch])
  }

  sendToResults(punches: Punch[]) {
    if (this.prefs.appPreferences.sendMethod === SendMethod.zr) {
      console.log("sending to zr: " + JSON.stringify(punches))
      const _this = this
      const obj = new SendToOtherService();
      obj.service = "zr"
      obj.punches = punches
      this.api.sendToOtherService(obj).subscribe((res) => {
        _this.notifyService.showSuccess(res.message)
      })
    } else {
      console.log("sending to orgeo: " + JSON.stringify(punches))
      const _this = this
      const obj = new SendToOtherService();
      obj.service = "orgeo"
      obj.orgeoUrl = this.prefs.appPreferences.orgeoUrl
      obj.punches = punches
      this.api.sendToOtherService(obj).subscribe((res) => {
        _this.notifyService.showSuccess(res.message)
      })
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  resendChoosen() {
    const obj = new SendToOtherService();
    var filteredByBib = this.filterByBibPipe.transform(this.data, this.searchText)
    if (this.prefs.appPreferences.sendMethod === SendMethod.zr) {
      obj.service = "zr"
      obj.punches = this.filterByPriorityPipe.transform(this.filterByPointPipe.transform(filteredByBib, this.selectedPoint).filter(x => x.isValid == true && x.isDuplicate == false), this.searchPriority)
      this.api.sendToOtherService(obj).subscribe((res) => {
        this.notifyService.showSuccess(res.message)
      })
    } else {
      obj.service = "orgeo"
      obj.orgeoUrl = this.prefs.appPreferences.orgeoUrl
      obj.punches = this.filterByPriorityPipe.transform(this.filterByPointPipe.transform(filteredByBib, this.selectedPoint).filter(x => x.isValid == true && x.isDuplicate == false), this.searchPriority)
      this.api.sendToOtherService(obj).subscribe((res) => {
        this.notifyService.showSuccess(res.message)
      })
    }
  }

  recalculateCount() {
    const filteredByBib = this.filterByBibPipe.transform(this.data, this.searchText);
    this.count = this.filterByPriorityPipe.transform(this.filterByPointPipe.transform(filteredByBib, this.selectedPoint).filter(x => x.isValid == true && x.isDuplicate == false), this.searchPriority).length
    this.updateSearchParams()
  }

  updateSearchParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        search: this.searchText,
        timingPoint: this.selectedPoint,
        priority: this.searchPriority,
      },
      queryParamsHandling: 'merge', // сохраняет существующие параметры
    });
  }

  invalidateOrValidatePunch(punch) {
    if (punch.isValid) {
      this.api.invalidatePunch(punch.id).subscribe(
        (res) => {
          this.data[this.data.indexOf(punch)].isValid = false
          this.recalculateCount()
          this.sortPunches(this.data)
          this.notifyService.showSuccess(res.message)
        },
        error => this.notifyService.showError(error.error.message)
      )
    } else {
      this.api.validatePunch(punch.id).subscribe(
        (res) => {
          this.data[this.data.indexOf(punch)].isValid = true
          this.recalculateCount()
          this.sortPunches(this.data)
          this.notifyService.showSuccess(res.message)
        },
        error => this.notifyService.showError(error.error.message)
      )
    }
  }

  needHighlightRowByBib(punch: Punch) {
    return punch.bib === "" || punch.bib === " " || punch?.bib == null
  }

  needHighlightBib(punch: Punch) {
    return punch.isValid == false || punch.isDuplicate
  }

  needHighlightRowByTimingPoint(punch: Punch) {
    return punch.timingPoint === "" || punch.timingPoint === " " || punch?.timingPoint == null
  }

  openEditPunchDialog(content, punch: Punch) {
    this.punchToEdit = punch
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then(
      (result) => {
        this.api.updatePunch(punch).subscribe(
          res => {
            this.loadPunches()
            this.notifyService.showSuccess("Punch updated")
          },
          error => this.notifyService.showError(error.error.message)
        )
        this.punchToEdit = null
      },
      (reason) => {
        this.punchToEdit = null
      });
  }

  openCreatePunchDialog(content) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then(
      (result) => {
        this.api.createPunch(this.punchToCreate).subscribe(
          res => {
            this.loadPunches()
            this.notifyService.showSuccess(`${res} punch created`)
            this.initializePunchForCreate()
          },
          error => this.notifyService.showError(error.error.message)
        )
      },
      (reason) => {
        this.initializePunchForCreate()
      });
  }

  initializePunchForCreate() {
    this.punchToCreate = new Punch();
    this.punchToCreate.deviceId = this.deviceId
  }

  createPunchButtonCanUnlocked(): boolean {
    return this.checkIfNotBlank(this.punchToCreate.timingPoint)
      && this.checkIfNotBlank(this.punchToCreate.timestamp)
      && this.checkIfNotBlank(this.punchToCreate.bib)
      && new Date(this.punchToCreate.timestamp).getTime() > 0
      && this.punchToCreate.timestamp.length > 10
  }

  checkIfNotBlank(str): boolean {
    return str != "" && str != null
  }

  parseCreatePunchString() {
    const splitted: string[] = this.punchCreateString.split("\t");
    this.punchToCreate.bib = splitted[0]
    this.punchToCreate.timestamp = splitted[1]
    this.punchToCreate.timingPoint = splitted[3]
    this.punchCreateString = ""
  }
}
