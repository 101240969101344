import {NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSliderModule} from '@angular/material/slider';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCommonModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {DevicesStateComponent} from './components/devices-state/devices-state.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpInterceptorService} from "./services/interceptors/HttpInterceptorService";
import {ToastrModule} from 'ngx-toastr';
import {LocationStrategy, PathLocationStrategy} from "@angular/common";
import {SortDirective} from "./helpers/sort/sort.directive";
import {PunchesComponent} from './components/punches/punches.component';
import {DevicePunchesComponent} from './components/device-punches/device-punches.component';
import {FilterListByBibPipe} from "./helpers/pipes/filter-list-by-bib.pipe";
import {FilterListByPointPipe} from './helpers/pipes/filter-by-point/filter-list-by-point.pipe';
import {ConfigsComponent} from './components/configs/configs.component';
import {AuthPageTokenComponent} from './components/auth-page-token/auth-page-token.component';
import {SettingsComponent} from './components/settings/settings.component';
import {FilterListByPriorityPipe} from "./helpers/pipes/filter-list-by-priority.pipe";
import {MatRadioModule} from "@angular/material/radio";

@NgModule({
  declarations: [
    AppComponent,
    DevicesStateComponent,
    SortDirective,
    PunchesComponent,
    DevicePunchesComponent,
    FilterListByBibPipe,
    FilterListByPointPipe,
    FilterListByPriorityPipe,
    ConfigsComponent,
    AuthPageTokenComponent,
    SettingsComponent,
  ],
  imports: [
    BrowserModule,
    MatSliderModule,
    MatSidenavModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSliderModule,
    MatCommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    ToastrModule.forRoot(
      {
        timeOut: 2500,
        positionClass: 'toast-top-right'
      }
    ),
    MatRadioModule
  ],
  providers: [
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
