import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private toastr: ToastrService
  ) {

  }

  showInfo(message, title = '') {
    this.toastr.info(message, title, {
      positionClass: 'toast-bottom-left'
    })
  }

  showSuccess(message, title = '') {
    this.toastr.success(message, title, {
      positionClass: 'toast-bottom-left'
    });
  }

  showError(message, title = '') {
    this.toastr.error(message, title, {
      positionClass: 'toast-bottom-left'
    });
  }

}
