import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Command, Config, Device} from 'src/app/domain/models';
import {OpenbandService} from 'src/app/services/openband/openband.service';
import {NotificationService} from "../../services/notifications/notification.service";
import {interval} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-devices-state',
  styleUrls: ['./devices-state.component.scss'],
  templateUrl: './devices-state.component.html'
})
export class DevicesStateComponent implements OnInit {

  data: Device[] = [];
  configs: Config[] = [];
  eventId: string = "";
  dayId: string = "";
  command: string = "";
  commands: string[] = [
    "resend_all_to_zone_results",
    "resend_all_to_openband",
    "resend_all_to_orgeo",
    "restart_tcp_server",
    "sync_config",
    "send_bib",
    "send_location",
  ]

  body: string = "";
  selectedDevice: Device;
  currentDevice: Device;
  private subscription

  compareSemanticVersions = (a: string, b: string) => {
    try {
      // 1. Split the strings into their parts.
      const a1 = a?.split('.');
      const b1 = b.split('.');
      // 2. Contingency in case there's a 4th or 5th version
      const len = Math.min(a1.length, b1.length);
      // 3. Look through each version number and compare.
      for (let i = 0; i < len; i++) {
        const a2 = +a1[i] || 0;
        const b2 = +b1[i] || 0;

        if (a2 !== b2) {
          return a2 > b2 ? 1 : -1;
        }
      }

      // 4. We hit this if the all checked versions so far are equal
      //
      return b1.length - a1.length;
    } catch (ex) {
      return 1;
    }
  };
  currentLastActiveTimeSortingIsDesc: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: OpenbandService,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    private titleService: Title,
  ) {
    this.eventId = this.route.snapshot.params.id1;
    titleService.setTitle(`Devices`)
  }

  expandDevice(device: Device) {
    var index = this.data.findIndex(x => x.id == device.id)
    if (this.data[index].commands.length == 0) {
      this.notifyService.showInfo("No commands to show")
      return
    }
    this.data[index].expanded = !this.data[index].expanded
  }

  containsConfig(obj: Config, list: Config[]) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i]?.id === obj?.id) {
        return true;
      }
    }

    return false;
  }

  loadDevices() {
    this.api.getDevices().subscribe((res) => {
      var processed: Device[] = []
      res.forEach(x => {
        if (this.containsConfig(x.config, this.configs) == false) this.configs.push(x.config)
        var d = new Device();
        d.name = x.name
        d.id = x.id
        d.lastActiveTime = x.lastActiveTime
        var deviceDate = Date.parse(d.lastActiveTime);
        var diff = Date.parse(new Date().toUTCString()) - deviceDate
        d.lastActiveTimeInSeconds = Math.round(diff / 1000);
        d.isOnline = diff < 60_000;
        d.commands = x.commands
        d.config = x.config
        d.location = x.location
        d.meta = x.meta
        d.priority = x.priority
        processed.push(d)
      })
      this.data = processed.sort((a, b) => {
        if (a.isOnline && b.isOnline == false) return -1
        if (a.isOnline == false && b.isOnline) return 1
        if (a.isOnline && b.isOnline) return 0
        // if (a.lastActiveTime == "" || a.lastActiveTime == null || a.lastActiveTimeInSeconds == null) return 11;
        // if (a.lastActiveTimeInSeconds > b.lastActiveTimeInSeconds) return 1;
        // if (a.lastActiveTimeInSeconds < b.lastActiveTimeInSeconds) return -1;
        return -1;
      })
      this.api.getConfigs().subscribe((res) => {
        res.forEach(x => {
          if (this.containsConfig(x, this.configs) == false) this.configs.push(x)
        })
      })
    })
  }

  deleteCommand(deviceId: string, command: Command) {
    this.api.deleteCommand(deviceId, command).subscribe(res => {
      this.notifyService.showSuccess(res.message)
      this.loadDevices()
    })
  }

  createCommand(deviceId: string, command: Command) {
    command.action = command.action.replace(" ", "")
    command.body = command?.body?.replace(" ", "")
    this.api.sendCommand(deviceId, command).subscribe(res => {
      this.notifyService.showSuccess(`Команда ${res.action} отправлена на ${res.deviceId} устройство`)
      this.loadDevices()
    })
  }

  updateDevice(device: Device) {
    this.api.updateDevice(device).subscribe(res => {
      // this.notifyService.showSuccess("Обновили")
    })
  }

  open2(content, device: Device) {
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      var commandToCreate = new Command();
      commandToCreate.action = this.command;
      commandToCreate.body = this.body;
      this.createCommand(device.id, commandToCreate)
    }, (reason) => {
    });
  }

  openLocationDialog(content, device: Device) {
    this.currentDevice = device
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      var commandToCreate = new Command();
      commandToCreate.action = this.command;
      commandToCreate.body = this.body;
      this.createCommand(device.id, commandToCreate)
    }, (reason) => {
    });
  }

  openDeviceLocation(device: Device, modal) {
    var link = `https://yandex.ru/maps/?ll=${device.location.longitude},${device.location.latitude}&mode=whatshere&whatshere[point]=${device.location.longitude},${device.location.latitude}&whatshere[zoom]=15.84&z=16.24`
    window.open(link, "_blank")
    modal.dismiss()
  }

  selectDevice(device: Device) {
    this.selectedDevice = device
  }

  syncDeviceLocation(device: Device, modal) {
    this.notifyService.showInfo("Начинаем синхронизацию местоположения")
    var commandToCreate = new Command();
    commandToCreate.action = "send_location";
    this.createCommand(device.id, commandToCreate)
    modal.dismiss()
  }

  ngOnInit(): void {
    this.loadDevices()
    this.subscription = interval(5000).subscribe((val) => {
      this.loadDevices()
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  sortByLastActiveTime() {
    // this.data.sort((a, b) => a?.device?.deviceInfo?.appVersion.replace(/\d+/g, n => n + 100000)
    //   .localeCompare(b?.device?.deviceInfo?.appVersion.replace(/\d+/g, n => n + 100000)));
    // this.data = this.data.sort((a, b) => {
    // return this.compareSemanticVersions(a?.device?.deviceInfo?.appVersion, b?.device?.deviceInfo?.appVersion)
    // })
  }

  linkConfig(device: Device, config: Config) {
    this.api.linkConfig(device.id, (config)).subscribe(
      result => {
        this.notifyService.showSuccess(result.message)
      },
      error => {
        this.notifyService.showError(error.error.message)
      }
    )
  }

  isDeviceHaveNiceLocation(device: Device) {
    if (device?.location == null || device?.location?.latitude == null || device?.location?.longitude == null) {
      return false
    } else {
      const deviceDate = Date.parse(device?.location?.updatedAt);
      const diff = Date.parse(new Date().toUTCString()) - deviceDate;
      return diff < 1000 * 60 * 60 * 6;
    }
  }

  randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  getDeviceTemperature(device: Device): string {
    if (
      (device?.meta?.temperature?.length == 0 || device?.meta?.temperature == null) &&
      (device?.meta?.readerTemperature?.length == 0 || device?.meta?.readerTemperature == null)
    ) return ""
    else {
      let temp = device?.meta?.temperature
      if (temp == null || temp.length == 0) temp = "0"
      let readerTemp = device?.meta?.readerTemperature
      if (readerTemp == null || readerTemp.length == 0) readerTemp = "0"
      return `${temp}°/${readerTemp}°`
    }
  }

  increasePriority(device: Device) {
    device.priority += 1
    this.api.updateDevice(device).subscribe(
      (res) => {
        this.notifyService.showSuccess("Priority updated")
      }
    )
  }
  decreasePriority(device: Device) {
    device.priority -= 1
    this.api.updateDevice(device).subscribe(
      (res) => {
        this.notifyService.showSuccess("Priority updated")
      }
    )
  }
}
