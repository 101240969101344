<div style="margin: 20px">
  <div style="margin-top: 20px; margin-bottom: 20px; display: inline">
    <div class="form-group">
      <label for="selected-point">Фильтр по точкам</label>
      <select class="form-control" id="selected-point" [(ngModel)]="selectedPoint" name="selected-point"
              ng-model="selectedPoint" ng-options="x for x in points" (ngModelChange)="recalculateCount()">
        <option *ngFor="let c of points" [ngValue]="c">{{c}}</option>
      </select>
    </div>
  </div>
  <div style="margin-top: 20px">
    <input type="email" class="form-control" id="search-text" aria-describedby="search-text"
           [(ngModel)]="searchText" placeholder="Фильтр по номерам" (ngModelChange)="recalculateCount()"
           autofocus>
  </div>

  <div style="margin-top: 20px">
    <input type="email" class="form-control" aria-describedby="search-text"
           [(ngModel)]="searchPriority" placeholder="Фильтр по приоритету" (ngModelChange)="recalculateCount()"
           autofocus>
  </div>

  <div style="margin: 20px">
    <button type="email" class="button-main button-center" (click)="resendChoosen()" aria-describedby="search-text"
            placeholder="Введите номер участника для поиска"
            autofocus>Отправить отфильтрованное {{count}}</button>

<!--    <button class="button-main button-center" (click)="downloadCsv()"-->
<!--            autofocus>Выгрузить</button>-->
  </div>
</div>

<table class="table">
  <thead>
  <tr>
    <th>#</th>
    <th>Device ID</th>
    <!--    <th>ID</th>-->
    <th>Timing point</th>
    <th>bib</th>
    <th>Timestamp</th>
    <th>Priority</th>
    <th>Actions</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let state of data | filterListByBib: searchText | filterListByPoint: selectedPoint | filterListByPriority: searchPriority"
                style="background-color: #000000;">
    <tr>
      <!--      <td>{{data.indexOf(state) + 1}}</td>-->
      <td>{{state.id}}</td>
      <td>
        <a class="button-link" style="font-size: 16px" routerLink="/devices/{{state?.deviceId}}/punches">{{state?.deviceId}}</a></td>
      <!--      <td>{{state?.id}}</td>-->
      <td [ngClass]="{'tr-highlighted': needHighlightRowByTimingPoint(state)}">
        {{state?.timingPoint}}
        <button class="btn" *ngIf="needHighlightRowByTimingPoint(state)" (click)="openEditPunchDialog(content2,state)">
          <i id="btn-punches" class="fa fa-pencil"></i></button>
      </td>
      <td style="font-weight: bold" [ngClass]="{'tr-highlighted': needHighlightRowByBib(state)}">
        <div [ngClass]="{'tr-default-text': needHighlightBib(state)}">
          {{state?.bib}}
        </div>
        <button class="btn" *ngIf="needHighlightRowByBib(state)" (click)="openEditPunchDialog(content2,state)"><i id="btn-punches" class="fa fa-pencil"></i></button>
      </td>
      <td>{{state?.timestamp}}</td>
      <td>{{state?.priority}}</td>
      <td>
        <div style="display: inline-flex; align-content: center">
          <button class="btn" *ngIf="state.isValid" (click)="sendPunchToResults(state)"><i id="btn-punches"
                                                                                      class="fa fa-send"></i>
          </button>
          <button class="btn" *ngIf="state.isValid" (click)="invalidateOrValidatePunch(state)"><i class="fa fa-ban"></i>
          </button>
          <button class="btn" *ngIf="!state.isValid" (click)="invalidateOrValidatePunch(state)"><i
            class="fa fa-refresh"></i></button>
        </div>
      </td>
    </tr>
  </ng-container>
  </tbody>
</table>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title">
      Редактирование отметки
    </h3>

    <button type="button" class="btn btn-danger" aria-label="Close" (click)="modal.dismiss('Cross click')">

      <span aria-hidden="true">
        ×
      </span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label class="label-black">ID</label>
        <input name="punch-id" class="form-control" onfocus="blur();" readonly="readonly" style="color: #787878;"
               [(ngModel)]="punchToEdit.id" [contentEditable]="false">

        <label class="label-black">Timing Point</label>
        <input name="punch-timing-point" class="form-control"
               [(ngModel)]="punchToEdit.timingPoint" autofocus>

        <label class="label-black">Bib</label>
        <input name="punch-bib" class="form-control"
               [(ngModel)]="punchToEdit.bib" autofocus>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Create click')">
      Обновить
    </button>
  </div>
</ng-template>
