import {Pipe, PipeTransform} from '@angular/core';
import {Punch} from "../../domain/models";

@Pipe({
  name: 'filterListByPriority'
})
export class FilterListByPriorityPipe implements PipeTransform {

  transform(items: Punch[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    return items.filter(punch => {
      return punch.priority == +searchText;
    });
  }

}
