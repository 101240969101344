<div class="div-content">
  <div class="div-input-token">
    <h5>From timestamp (UTC)</h5>
    <div class="div-input-token-row">
      <input [value]="from"
             [(ngModel)]="from"
             autofocus>
      <button class="button-yellow" (click)="updateTimestamp()">Refresh Time (-5h)</button>
      <button class="button-yellow" (click)="updateTimestampToCurrent()">Set current time</button>
      <button class="button-yellow" (click)="updateTimestampToCurrentDay()">Set current day</button>
    </div>

    <h5>Send method</h5>
    <input [value]="orgeoUrl"
           [(ngModel)]="orgeoUrl"
           style="min-width: 430px"
           autofocus>
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="example-radio-group"
      [(ngModel)]="selectedSendType">
      <div *ngFor="let item of sendItems">
        <mat-radio-button [value]="item">{{item}}</mat-radio-button>
      </div>
    </mat-radio-group>
    <button class="button-main" (click)="apply()">Apply</button>
  </div>
</div>
