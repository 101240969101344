import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {OpenbandService} from "../../services/openband/openband.service";
import {NotificationService} from "../../services/notifications/notification.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Title} from "@angular/platform-browser";
import {PreferencesService} from "../../services/preferences/preferences.service";
import {AppPreferences, SendMethod} from "../../domain/models";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  from: string = "";
  prefs: AppPreferences = new AppPreferences();
  selectedSendType: SendMethod = SendMethod.zr;
  sendItems: SendMethod[] = [
    SendMethod.zr,
    SendMethod.orgeo,
  ];
  orgeoUrl: string = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: OpenbandService,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    private titleService: Title,
    private preferences: PreferencesService,
  ) {
    this.prefs = preferences.appPreferences
    this.selectedSendType = preferences.appPreferences.sendMethod
    this.orgeoUrl = this.prefs.orgeoUrl
    this.from = this.parseMillis(this.prefs.fromTimestamp)
    titleService.setTitle(`Settings`)
  }

  reloadPrefs() {
    this.prefs = this.preferences.appPreferences
    this.orgeoUrl = this.prefs.orgeoUrl
    this.from = this.parseMillis(this.prefs.fromTimestamp)
  }

  apply() {
    //TODO add parsing timestamp
    console.log("selected type: " + this.selectedSendType)
    this.prefs.fromTimestamp = this.parseTimestamp()
    this.prefs.sendMethod = SendMethod[this.selectedSendType]
    this.prefs.orgeoUrl = this.orgeoUrl
    this.preferences.setAppPreferences(this.prefs)
    this.notifyService.showSuccess("Настройки сохранены")
  }

  updateTimestamp() {
    this.prefs.fromTimestamp = Date.now() - 1000 * 60 * 60 * 5
    this.preferences.setAppPreferences(this.prefs)
    this.notifyService.showSuccess("Время установлено")
    this.reloadPrefs()
  }

  updateTimestampToCurrent() {
    this.prefs.fromTimestamp = Date.now()
    this.preferences.setAppPreferences(this.prefs)
    this.notifyService.showSuccess("Время установлено")
    this.reloadPrefs()
  }

  private parseMillis(millis): string {
    console.log(millis)
    if (millis == undefined) return ""
    var date = new Date(millis);
    return date.toISOString()
  }

  private parseTimestamp(): number {
    var myDate = Date.parse(this.from);
    var result = myDate;
    console.log(result)
    return result
  }

  updateTimestampToCurrentDay() {
    let date = new Date();
    let dateInString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}T00:00:00.000Z`
    console.log(dateInString)
    this.prefs.fromTimestamp = Date.parse(dateInString)
    this.preferences.setAppPreferences(this.prefs)
    this.notifyService.showSuccess("Время установлено на начало текущих суток")
    this.reloadPrefs()
    return
  }
}
