import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UrlUtils} from '../utils/url-utils';
import {Command, Config, Device, OrgeoTagReaderRequest, Punch, SendToOtherService,} from 'src/app/domain/models';
import {NotificationService} from "../notifications/notification.service";

@Injectable({
  providedIn: 'root'
})
export class OpenbandService {

  private baseUrl = UrlUtils.getProdServer();
  private SLASH = '/';
  private DEVICES = 'devices';
  private PUNCHES = 'punches';
  private CONFIGS = 'configs';
  private COMMANDS = 'commands';
  private SEND = 'send';
  private URI_DEVICES = this.baseUrl + this.SLASH + this.DEVICES;
  private URI_PUNCHES = this.baseUrl + this.SLASH + this.PUNCHES;
  private URI_CONFIGS = this.baseUrl + this.SLASH + this.CONFIGS;
  private URI_DEVICES_FULL = this.baseUrl + this.SLASH + this.DEVICES + this.SLASH + "full";

  constructor(
    private http: HttpClient,
    private notifyService: NotificationService
  ) {
  }

  getDevices(): Observable<Device[]> {
    return this.http.get<Device[]>(this.URI_DEVICES_FULL)
  }

  getDevice(id): Observable<Device> {
    return this.http.get<Device>(this.URI_DEVICES + this.SLASH + id)
  }

  getPunches(): Observable<Punch[]> {
    return this.http.get<Punch[]>(this.URI_PUNCHES)
  }

  getPunchesFrom(from: number): Observable<Punch[]> {
    let _from = from;
    if (_from == null) _from = Date.now() - 604800000
    return this.http.get<Punch[]>(
      this.URI_PUNCHES,
      {
        params: {
          from: _from
        }
      }
    )
  }

  getPunchesByDeviceId(id: string): Observable<Punch[]> {
    return this.http.get<Punch[]>(this.URI_PUNCHES + "/" + id)
  }

  getPunchesByDeviceIdFrom(id: string, from: number): Observable<Punch[]> {
    let _from = from;
    if (_from == null) _from = Date.now() - 604800000
    return this.http.get<Punch[]>(this.URI_PUNCHES + "/" + id, {
      params: {
        from: _from
      }
    })
  }

  updatePunch(punch: Punch): Observable<Punch> {
    return this.http.patch<Punch>(this.URI_PUNCHES + this.SLASH + punch?.id, punch)
  }

  createPunch(punch: Punch): Observable<string> {
    return this.http.post<string>(this.URI_PUNCHES, [punch])
  }

  validatePunch(id: number): Observable<MessageResponse> {
    return this.http.patch<MessageResponse>(this.URI_PUNCHES + this.SLASH + id + this.SLASH + "validate", null)
  }

  invalidatePunch(id: number): Observable<MessageResponse> {
    return this.http.patch<MessageResponse>(this.URI_PUNCHES + this.SLASH + id + this.SLASH + "invalidate", null)
  }

  updateDevice(device: Device): Observable<Device> {
    return this.http.patch<Device>(this.URI_DEVICES + this.SLASH + device.id, device)
  }

  sendCommand(deviceId: string, command: Command): Observable<Command> {
    return this.http.post<Command>(this.URI_DEVICES + this.SLASH + deviceId + this.SLASH + this.COMMANDS, command)
  }

  deleteCommand(deviceId: string, command: Command): Observable<MessageResponse> {
    return this.http.patch<MessageResponse>(this.URI_DEVICES + this.SLASH + deviceId + this.SLASH + this.COMMANDS, command)
  }

  sendToOtherService(obj: SendToOtherService): Observable<MessageResponse> {
    return this.http.post<MessageResponse>(this.URI_PUNCHES + this.SLASH + this.SEND, obj)
  }

  getConfigs(): Observable<Config[]> {
    return this.http.get<Config[]>(this.URI_CONFIGS)
  }

  createConfig(config: Config): Observable<Config> {
    return this.http.post<Config>(this.URI_CONFIGS, config)
  }

  updateConfig(configId: string, config: Config): Observable<Config> {
    return this.http.patch<Config>(this.URI_CONFIGS + this.SLASH + configId, config)
  }

  linkConfig(deviceId: string, config: Config): Observable<MessageResponse> {
    return this.http.patch<MessageResponse>(this.URI_DEVICES + this.SLASH + deviceId + this.SLASH + "link_config", config)
  }

  sendToOrgeo(url: string, body: OrgeoTagReaderRequest): Observable<any> {
    return this.http.post<any>(url, body
    )
  }
}

export class BaseResponse {
  response: any;
  errors: BaseError[];
}

export class BaseError {
  code: string;
  message: string;
  details: string;
}

export class MessageResponse {
  message: string;
}
