import {Component} from '@angular/core';
import {Punch, SendMethod, SendToOtherService} from "../../domain/models";
import {ActivatedRoute, Router} from "@angular/router";
import {OpenbandService} from "../../services/openband/openband.service";
import {NotificationService} from "../../services/notifications/notification.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {interval} from "rxjs";
import {FilterListByBibPipe} from "../../helpers/pipes/filter-list-by-bib.pipe";
import {FilterListByPointPipe} from "../../helpers/pipes/filter-by-point/filter-list-by-point.pipe";
import {Title} from "@angular/platform-browser";
import {PreferencesService} from "../../services/preferences/preferences.service";
import {FilterListByPriorityPipe} from "../../helpers/pipes/filter-list-by-priority.pipe";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-punches',
  templateUrl: './punches.component.html',
  styleUrls: ['./punches.component.scss']
})
export class PunchesComponent {

  data: Punch[];
  private subscription
  searchText = '';
  points: string[] = ["-"]

  selectedPoint;
  searchPriority = '';

  filterByBibPipe = new FilterListByBibPipe();
  filterByPointPipe = new FilterListByPointPipe();
  filterByPriorityPipe = new FilterListByPriorityPipe();

  count: number = 0

  punchToEdit: Punch;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: OpenbandService,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    private titleService: Title,
    private prefs: PreferencesService,
    private clipboard: Clipboard,
  ) {
    this.route.queryParams.subscribe(params => {
      this.searchText = params['search'];
      this.selectedPoint = params['timingPoint'];
      this.searchPriority = params['priority']
    });
    titleService.setTitle(`All punches`)
  }

  containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }

    return false;
  }

  loadPunches() {
    this.api.getPunchesFrom(this.prefs.appPreferences.fromTimestamp).subscribe((res) => {
      this.sortPunches(res)
    })
  }

  sortPunches(res: Punch[]) {
    this.data = res.sort((a, b) => {
      if (a.timestamp == "" || a.timestamp == null || a.timestamp == null) return 11;
      if (a.timestamp < b.timestamp) return 1;
      if (a.timestamp > b.timestamp) return -1;
      return -1;
    }).reverse()

    let bibs = {}
    this.data.forEach(punch => {
      if (punch.bib.length > 0 && punch.isValid) {
        if (bibs[punch.bib]) {
          bibs[punch.bib] += 1;
        } else {
          bibs[punch.bib] = 1;
        }
        if (bibs[punch.bib] > 1) punch.isDuplicate = true; else punch.isDuplicate = false
      }
    });
    this.data = this.data.reverse()
    this.recalculateCount()
    this.loadTimingPoints()
  }

  loadTimingPoints() {
    this.data.forEach(x => {
      if (this.containsObject(x.timingPoint, this.points) == false) {
        this.points.push(x.timingPoint)
      }
    })
  }

  sendPunchToResults(punch: Punch) {
    this.sendToResults([punch])
  }

  sendToResults(punches: Punch[]) {
    if (this.prefs.appPreferences.sendMethod === SendMethod.zr) {
      console.log("sending to zr: " + JSON.stringify(punches))
      const _this = this
      const obj = new SendToOtherService();
      obj.service = "zr"
      obj.punches = punches
      this.api.sendToOtherService(obj).subscribe((res) => {
        _this.notifyService.showSuccess(res.message)
      })
    } else {
      console.log("sending to orgeo: " + JSON.stringify(punches))
      const _this = this
      const obj = new SendToOtherService();
      obj.service = "orgeo"
      obj.orgeoUrl = this.prefs.appPreferences.orgeoUrl
      obj.punches = punches
      this.api.sendToOtherService(obj).subscribe((res) => {
        _this.notifyService.showSuccess(res.message)
      })
    }
  }

  resendChoosen() {
    const obj = new SendToOtherService();
    var filteredByBib = this.filterByBibPipe.transform(this.data, this.searchText)
    if (this.prefs.appPreferences.sendMethod === SendMethod.zr) {
      obj.service = "zr"
      obj.punches = this.filterByPriorityPipe.transform(this.filterByPointPipe.transform(filteredByBib, this.selectedPoint).filter(x => x.isValid == true && x.isDuplicate == false), this.searchPriority)
      this.api.sendToOtherService(obj).subscribe((res) => {
        this.notifyService.showSuccess(res.message)
      })
    } else {
      obj.service = "orgeo"
      obj.orgeoUrl = this.prefs.appPreferences.orgeoUrl
      obj.punches = this.filterByPriorityPipe.transform(this.filterByPointPipe.transform(filteredByBib, this.selectedPoint).filter(x => x.isValid == true && x.isDuplicate == false), this.searchPriority)
      this.api.sendToOtherService(obj).subscribe((res) => {
        this.notifyService.showSuccess(res.message)
      })
    }
  }

  ngOnInit(): void {
    this.loadPunches()
    this.subscription = interval(10000).subscribe((val) => {
      this.loadPunches()
    })

  }

  recalculateCount() {
    const filteredByBib = this.filterByBibPipe.transform(this.data, this.searchText);
    this.count = this.filterByPriorityPipe.transform(this.filterByPointPipe.transform(filteredByBib, this.selectedPoint).filter(x => x.isValid == true && x.isDuplicate == false), this.searchPriority).length
    this.updateSearchParams()
  }

  updateSearchParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        search: this.searchText,
        timingPoint: this.selectedPoint,
        priority: this.searchPriority,
      },
      queryParamsHandling: 'merge', // сохраняет существующие параметры
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  invalidateOrValidatePunch(punch) {
    if (punch.isValid) {
      this.api.invalidatePunch(punch.id).subscribe(
        (res) => {
          this.data[this.data.indexOf(punch)].isValid = false
          this.recalculateCount()
          this.sortPunches(this.data)
          this.notifyService.showSuccess(res.message)
        },
        error => this.notifyService.showError(error.error.message)
      )
    } else {
      this.api.validatePunch(punch.id).subscribe(
        (res) => {
          this.data[this.data.indexOf(punch)].isValid = true
          this.recalculateCount()
          this.sortPunches(this.data)
          this.notifyService.showSuccess(res.message)
        },
        error => this.notifyService.showError(error.error.message)
      )
    }
  }

  needHighlightRowByBib(punch: Punch) {
    return punch.bib === "" || punch.bib === " " || punch?.bib == null
  }

  needHighlightRowByTimingPoint(punch: Punch) {
    return punch.timingPoint === "" || punch.timingPoint === " " || punch?.timingPoint == null
  }

  needHighlightBib(punch: Punch) {
    return punch.isValid == false || punch.isDuplicate
  }

  openEditPunchDialog(content, punch: Punch) {
    this.punchToEdit = punch
    this.modalService.open(content,
      {ariaLabelledBy: 'modal-basic-title'}).result.then(
      (result) => {
        this.api.updatePunch(punch).subscribe(
          res => {
            this.loadPunches()
            this.notifyService.showSuccess("Punch updated")
          },
          error => this.notifyService.showError(error.error.message)
        )
        this.punchToEdit = null
      },
      (reason) => {
        this.punchToEdit = null
      });
  }

  copyAsCSV() {


  }

  downloadCsv() {
    var tab = "\t"
    var crlf = "\n"
    // var s = "name" + tab + "code" + tab + "minor" + tab + "major" + tab + "latitude" + tab + "longitude" + tab + "beginWorkingTime" + tab + "endWorkingTime" + tab + "qrHash" + crlf
    var s = ""
    this.data.forEach(punch => {
      s += punch.bib + tab + punch.timestamp + tab + punch.timingPoint + tab + punch.isValid + tab + punch.priority + tab + punch.isDuplicate + crlf
    })

    this.clipboard.copy(s)
    this.notifyService.showSuccess("Copied to clipboard!")

    // this.data.sort((a, b) => {
    //   if (a.bib > b.bib) return 1
    //   if (a.bib < b.bib) return -1
    //   return 0
    // }).forEach(punch => {
    //
    // })
  }
}
