// Devices

export class Device {
  id = '';
  lastActiveTime: string;
  lastActiveTimeInSeconds: number;
  name: string;
  fcmPushToken: string;
  isOnline: boolean = false;
  expanded: boolean = true;
  commands: Command[];
  config: Config;
  location: Location;
  meta: Meta;
  priority: number;
}

export class Meta {
  platform: string;
  appVersion: string;
  imaginaryId: string;
  manufacturer: string;
  model: string;
  systemVersion: string;
  temperature: string;
  readerTemperature: string;
}

export class Punch {
  id: string;
  timestamp: string;
  deviceId: string;
  timingPoint: string;
  bib: string;
  isValid: boolean
  priority: number
  isDuplicate: boolean = false
}

export class Command {
  deviceId: number;
  action: string;
  body: string;
  uuid: string;
}

export class AppPreferences {
  token: string;
  fromTimestamp: number = 1725546303539;
  sendMethod: SendMethod = null;
  orgeoUrl: string = "";
}

export enum SendMethod {
  zr = "zr",
  orgeo = "orgeo",
}

export class OrgeoTagReaderRequest {
  Antenna: string = "";
  DateTime: string = "";
  ReadCount: string = "";
  TagID: string = "";

  constructor(
    antenna: string,
    dateTime: string,
    readCount: string,
    tagId: string,
  ) {
    this.Antenna = antenna
    this.DateTime = dateTime
    this.ReadCount = readCount
    this.TagID = tagId
  }

}

export class SendToOtherService {
  service: string;
  orgeoUrl: string;
  punches: Punch[];
}

export class Config {
  id: string;
  lastUpdateTimestamp: string;
  name: string;
  orgeo: OrgeoConfig = new OrgeoConfig();
  rfidReader: RfidReaderConfig;
  zoneResults: ZoneResultConfig;
  rfidReaderBox: RfidReaderBoxConfig;
  timingSettings: TimingPointConfig;
}

export class OrgeoConfig {
  enabled: boolean;
  sendLegacyEnabled: boolean;
  sendEnabled: boolean;
  url: string;
  antennaId: string;
}

export class RfidReaderConfig {
  enabled: boolean;
  ip: string;
  port: number;
  eventPin: string;
  punchIntervalInMilliseconds: number;
  startBib: string;
  endBib: string;
}

export class RfidReaderBoxConfig {
  enabled: boolean;
  ip: string;
  port: number;
  eventPin: string;
  punchIntervalInMilliseconds: number;
}

export class ZoneResultConfig {
  enabled: boolean;
  ip: string;
  port: number;
  source: string;
  timingPoint: string;
  type: string;
  antenna: string;
}

export class TimingPointConfig {
  source: string;
  timingPoint: string;
  type: string;
  antenna: string;
}

export class Location {
  updatedAt: string = "2024-09-03T01:01:40.492+03:00";
  latitude: number = 55.33;
  longitude: number = 33.33;
  accuracy: number = 1;
}
